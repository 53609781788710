import React, { useState } from "react";
import { Link } from "react-router-dom";
import { tw } from "twind";

export default function Navigation() {
    const [isOpen, setIsOpen] = useState(false);

    return (
        <nav className={tw`bg-green`}>
            <div className={tw`max-w-7xl mx-auto px-2 sm:px-6 lg:px-8`}>
                <div
                    className={tw`relative flex items-center justify-between h-16`}
                >
                    <div
                        className={tw`absolute inset-y-0 left-0 flex items-center sm:hidden`}
                    >
                        <button
                            type="button"
                            className={tw`inline-flex items-center justify-center p-2 rounded-md text-gray-400 hover:text-white hover:bg-green-600 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white`}
                            aria-controls="mobile-menu"
                            aria-expanded="false"
                            onClick={() => setIsOpen(!isOpen)}
                        >
                            <svg
                                className={tw`block h-6 w-6`}
                                xmlns="http://www.w3.org/2000/svg"
                                fill="none"
                                viewBox="0 0 24 24"
                                stroke="currentColor"
                                aria-hidden="true"
                            >
                                <path
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    strokeWidth="2"
                                    d="M4 6h16M4 12h16M4 18h16"
                                />
                            </svg>
                            <svg
                                className={tw`hidden h-6 w-6`}
                                xmlns="http://www.w3.org/2000/svg"
                                fill="none"
                                viewBox="0 0 24 24"
                                stroke="currentColor"
                                aria-hidden="true"
                            >
                                <path
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    strokeWidth="2"
                                    d="M6 18L18 6M6 6l12 12"
                                />
                            </svg>
                        </button>
                    </div>
                    <div
                        className={tw`flex-1 flex items-center justify-center sm:items-stretch sm:justify-start`}
                    >
                        <div className={tw`flex-shrink-0 flex items-center`}>
                            <img
                                className={tw`block h-8 w-auto`}
                                src="/transparent_logo.svg"
                                alt="logo"
                            />
                        </div>
                        <div
                            className={tw`hidden sm:block sm:ml-6 flex flex-1`}
                        >
                            <div className={tw`flex space-x-4 flex flex-1`}>
                                <Link
                                    to="/"
                                    className={tw` hover:bg-green-600 text-white px-3 py-2 rounded-md text-sm font-medium`}
                                >
                                    Venues
                                </Link>
                                <Link
                                    className={tw` hover:bg-green-600 text-white px-3 py-2 rounded-md text-sm font-medium`}
                                    to="/events"
                                >
                                    Events
                                </Link>
                                <Link
                                    to="/categories"
                                    className={tw`text-white hover:bg-green-600 hover:text-white px-3 py-2 rounded-md text-sm font-medium`}
                                >
                                    Categories
                                </Link>
                                <Link
                                    to="/users"
                                    className={tw`text-white hover:bg-green-600 hover:text-white px-3 py-2 rounded-md text-sm font-medium`}
                                >
                                    Users
                                </Link>
                                <Link
                                    to="/pins"
                                    className={tw`text-white hover:bg-green-600 hover:text-white px-3 py-2 rounded-md text-sm font-medium`}
                                >
                                    Custom Pins
                                </Link>
                                <Link
                                    to="/announcements"
                                    className={tw`text-white hover:bg-green-600 hover:text-white px-3 py-2 rounded-md text-sm font-medium`}
                                >
                                    Announcements
                                </Link>
                                <Link
                                    to="/media"
                                    className={tw`text-white hover:bg-green-600 hover:text-white px-3 py-2 rounded-md text-sm font-medium`}
                                >
                                    Media
                                </Link>
                                <Link
                                    to="/Upload"
                                    className={tw`text-white hover:bg-green-600 hover:text-white px-3 py-2 rounded-md text-sm font-medium`}
                                >
                                    Upload
                                </Link>
                                <Link
                                    to="/logout"
                                    className={tw`text-white hover:bg-green-600 hover:text-white px-3 py-2 rounded-md text-sm font-medium self-end`}
                                    style={{ marginLeft: "auto" }}
                                >
                                    Logout
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {isOpen && (
                <div className={tw`sm:hidden`} id="mobile-menu">
                    <div className={tw`="px-2 pt-2 pb-3 space-y-1`}>
                        <Link
                            to="/"
                            className={tw`text-white hover:bg-green-600 hover:text-white block px-3 py-2 rounded-md text-base font-medium`}
                        >
                            Venues
                        </Link>
                        <Link
                            to="/events"
                            className={tw`text-white hover:bg-green-600 hover:text-white block px-3 py-2 rounded-md text-base font-medium`}
                        >
                            Events
                        </Link>
                        <Link
                            to="categories"
                            className={tw`text-white hover:bg-green-600 hover:text-white block px-3 py-2 rounded-md text-base font-medium`}
                        >
                            Categories
                        </Link>
                    </div>
                </div>
            )}
        </nav>
    );
}
