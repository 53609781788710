import CsvImport from "components/csv-import/CsvImport";
import Navigation from "components/navigation/Navigation";
import React from "react";
import { tw } from "twind";

export default function Upload() {
    return (
        <>
            <Navigation />
            <div className={tw`max-w-7xl mx-auto py-8 sm:px-6 lg:px-8`}>
                <div className={tw`flex flex-col`}>
                    <div
                        className={tw`-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8`}
                    >
                        <div
                            className={tw`py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8`}
                        >
                            <h1 className={tw`text-2xl py-4`}>Upload CSV</h1>

                            <CsvImport />
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}
