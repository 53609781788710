import { useQuery } from "@apollo/client";
import Dropdown from "components/dropdown/Dropdown";
import { Media } from "components/media/MediaItem";
import { GET_CATEGORIES } from "graphql/queries";
import React from "react";

interface Props {
    value?: string;
    setValue(value: string): void;
}

export interface Category {
    id: string;
    name: string;
    svg: string;
    imageUrl?: string;
    svgUrl: string;
}

export type Categories = Category[];

export default function CategoryDropdown({ value, setValue }: Props) {
    const { data } = useQuery<{ categories: Categories }>(GET_CATEGORIES);
    return data?.categories ? (
        <Dropdown
            zIndex={30}
            items={data?.categories}
            onSelect={(item) => setValue(item.id)}
            selectedItemId={value}
        />
    ) : null;
}
