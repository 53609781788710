import React from "react";
import MDEditor from "@uiw/react-md-editor";

interface Props {
    markdown: string;
    onChange?(markdown?: string): void;
}

export default function MarkdownEditor({ markdown, onChange }: Props) {
    return (
        <div className="container" style={{ minHeight: 500 }}>
            <MDEditor height={500} value={markdown} onChange={onChange} />
        </div>
    );
}
