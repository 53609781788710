import React, { ChangeEvent, useEffect, useState } from "react";
import { DebounceInput } from "react-debounce-input";
import { tw } from "twind";
import { ReactComponent as CloseIcon } from "../dropdown/close.svg";

type WithId = {
    id: string;
    name: string;
};

interface Props<T> {
    items: (T & WithId)[];
    onSelect(item: T & WithId): void;
    selectedItemId?: string;
    zIndex?: number;
}

export default function Dropdown<U extends WithId>({
    items,
    onSelect,
    selectedItemId,
    zIndex = 20,
}: Props<U & WithId>) {
    const [isOpen, setIsOpen] = useState(false);
    const [filterText, setFilterText] = useState("");

    const selectedItem = items?.find((item) => item.id === selectedItemId);

    const filteredItems = filterText
        ? items.filter(
              (item) =>
                  item.name
                      ?.toLowerCase()
                      .indexOf(filterText?.toLowerCase()) !== -1
          )
        : items;

    const sortedItems = [...(filteredItems || [])].sort((a, b) =>
        a.id === selectedItemId
            ? -1
            : b.id === selectedItemId
            ? 1
            : a.name > b.name
            ? 1
            : -1
    );

    function onChange(event: ChangeEvent<HTMLInputElement>) {
        setFilterText(event.target.value);
    }

    useEffect(() => {
        if (selectedItem?.name) {
            setFilterText(selectedItem.name);
        }
    }, [selectedItem]);

    return (
        <div className={tw(`mt-1 relative`, `z-${zIndex}`)}>
            <div
                className={tw`relative w-full bg-white border border-gray-300 rounded-md shadow-sm pl-3 pr-10 py-2 text-left cursor-default focus:outline-none focus:ring-1 focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm`}
            >
                <span className={tw`flex items-center`}>
                    <DebounceInput
                        onFocus={() => {
                            setIsOpen(true);
                            setFilterText("");
                        }}
                        debounceTimeout={300}
                        className={tw`bg-white text-gray-900 appearance-none block w-full  py-1  focus:border-blue-500 focus:outline-none`}
                        type="text"
                        placeholder="Type or click to search"
                        onChange={onChange}
                        value={filterText}
                    />
                    {isOpen && (
                        <button
                            onClick={() => {
                                setFilterText(selectedItem?.name || "");
                                setIsOpen(false);
                            }}
                            type="button"
                        >
                            <CloseIcon width="20" height="20" />
                        </button>
                    )}
                </span>
                <span
                    className={tw`ml-3 absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none`}
                >
                    <button
                        onClick={() => {
                            setIsOpen((prev) => !prev);
                        }}
                        type="button"
                    >
                        <svg
                            className={tw`h-5 w-5 text-gray-400`}
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 20 20"
                            fill="currentColor"
                            aria-hidden="true"
                        >
                            <path
                                fillRule="evenodd"
                                d="M10 3a1 1 0 01.707.293l3 3a1 1 0 01-1.414 1.414L10 5.414 7.707 7.707a1 1 0 01-1.414-1.414l3-3A1 1 0 0110 3zm-3.707 9.293a1 1 0 011.414 0L10 14.586l2.293-2.293a1 1 0 011.414 1.414l-3 3a1 1 0 01-1.414 0l-3-3a1 1 0 010-1.414z"
                                clipRule="evenodd"
                            />
                        </svg>
                    </button>
                </span>
            </div>

            {isOpen && (
                <div
                    className={tw`absolute mt-1 w-full rounded-md bg-white shadow-lg`}
                >
                    <ul
                        tabIndex={-1}
                        role="listbox"
                        aria-labelledby="listbox-label"
                        aria-activedescendant="listbox-item-3"
                        className={tw`max-h-56 min-h-56 rounded-md py-1 text-base ring-1 ring-black ring-opacity-5 overflow-auto focus:outline-none sm:text-sm`}
                    >
                        {sortedItems?.map((item) => (
                            <li
                                onClick={() => {
                                    onSelect(item);
                                    setIsOpen(false);
                                }}
                                id="listbox-item-0"
                                className={tw`text-gray-900 cursor-default select-none relative py-2 pl-3 pr-9`}
                            >
                                <div className={tw`flex items-center`}>
                                    {/* <img
                                src="https://images.unsplash.com/photo-1491528323818-fdd1faba62cc?ixlib=rb-1.2.1&amp;ixid=eyJhcHBfaWQiOjEyMDd9&amp;auto=format&amp;fit=facearea&amp;facepad=2&amp;w=256&amp;h=256&amp;q=80"
                                alt=""
                                className={tw`flex-shrink-0 h-6 w-6 rounded-full`}
                            /> */}

                                    <span
                                        className={tw`ml-3 block font-normal truncate`}
                                    >
                                        {item.name}
                                    </span>
                                </div>

                                <span
                                    className={tw`absolute inset-y-0 right-0 flex items-center pr-4`}
                                >
                                    {item.id === selectedItemId && (
                                        <svg
                                            className={tw`h-5 w-5`}
                                            xmlns="http://www.w3.org/2000/svg"
                                            viewBox="0 0 20 20"
                                            fill="currentColor"
                                            aria-hidden="true"
                                        >
                                            <path
                                                fillRule="evenodd"
                                                d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                                                clipRule="evenodd"
                                            />
                                        </svg>
                                    )}
                                </span>
                            </li>
                        ))}
                    </ul>
                </div>
            )}
        </div>
    );
}
