import { useQuery } from "@apollo/client";
import Modal from "components/user-modal/UserModal";
import Table from "components/table/Table";
import { GET_USERS } from "graphql/queries";
import { useState } from "react";
import Navigation from "components/navigation/Navigation";

export default function Users() {
    const { data } = useQuery(GET_USERS);
    const [modalVisible, setModalVisible] = useState(false);

    return (
        <>
            <Navigation />
            <Table
                title="Users"
                columns={[
                    { name: "Email", value: "email" },
                    { name: "Role", value: "role" },
                ]}
                onAdd={() => setModalVisible(true)}
                data={data?.users || []}
            />
            {modalVisible && (
                <Modal
                    onSubmit={() => setModalVisible(false)}
                    onClose={() => setModalVisible(false)}
                />
            )}
        </>
    );
}
