import Input from "components/input/Input";
import { tw } from "twind";

interface Props {
    handleInput(event: any): void;
    value: string;
    status: string;
    data: any[];
    handleSelect: (suggestion: any) => () => void;
}
export default function Address({
    handleInput,
    value,
    status,
    data,
    handleSelect,
}: Props) {
    return (
        <>
            <Input
                type="text"
                name="address"
                id="address"
                value={value}
                onChange={handleInput}
            />
            {status === "OK" && (
                <ul>
                    {data.map((suggestion) => {
                        const {
                            place_id,
                            structured_formatting: {
                                main_text,
                                secondary_text,
                            },
                        } = suggestion;

                        return (
                            <li
                                key={place_id}
                                onClick={handleSelect(suggestion)}
                                className={tw`cursor-pointer `}
                            >
                                <strong>{main_text}</strong>{" "}
                                <small>{secondary_text}</small>
                            </li>
                        );
                    })}
                </ul>
            )}
        </>
    );
}
