import { useMutation, useQuery } from "@apollo/client";
import { LOGIN } from "graphql/mutations";
import { ME } from "graphql/queries";
import { useEffect, useState } from "react";
import { Redirect } from "react-router-dom";
import { tw } from "twind";

export default function Login() {
    const [login, { data, error }] = useMutation(LOGIN);
    const { data: userData, refetch, loading } = useQuery(ME);
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");

    function onSubmit(event: any) {
        event.preventDefault();
        login({ variables: { email, password } });
    }

    useEffect(() => {
        if (data?.login) {
            refetch(); // Background.tsx will push to dashboard if logged in
        }
    }, [data?.login, refetch]);

    if (userData?.me?.id) {
        return <Redirect to="/" />;
    }

    if (loading) {
        return null;
    }

    return (
        <div
            className={tw`min-h-screen flex items-center justify-center bg-gray-50 py-12 px-4 sm:px-6 lg:px-8`}
        >
            <div className={tw`max-w-md w-full space-y-8`}>
                <div>
                    <img
                        className={tw`mx-auto h-24 w-auto`}
                        src="/logo-inverted.svg"
                        alt="Workflow"
                    />
                    <h2
                        className={tw`mt-6 text-center text-3xl font-extrabold text-gray-900`}
                    >
                        Sign in to your account
                    </h2>
                </div>
                <form
                    className={tw`mt-8 space-y-6`}
                    action="#"
                    method="POST"
                    onSubmit={onSubmit}
                >
                    <input type="hidden" name="remember" value="true" />
                    <div className={tw`rounded-md shadow-sm -space-y-px`}>
                        <div>
                            <label
                                htmlFor="email-address"
                                className={tw`sr-only`}
                            >
                                Email address
                            </label>
                            <input
                                id="email-address"
                                name="email"
                                type="email"
                                autoComplete="email"
                                required
                                value={email}
                                placeholder="john@doe.com"
                                onChange={(event: any) =>
                                    setEmail(event.target.value)
                                }
                                className={tw`appearance-none rounded-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-t-md focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm`}
                            />
                        </div>
                        <div>
                            <label htmlFor="password" className={tw`sr-only`}>
                                Password
                            </label>
                            <input
                                id="password"
                                name="password"
                                type="password"
                                autoComplete="current-password"
                                required
                                value={password}
                                onChange={(event: any) =>
                                    setPassword(event.target.value)
                                }
                                className={tw`appearance-none rounded-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-b-md focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm`}
                            />
                        </div>
                    </div>

                    {data?.login === false ||
                        (error && (
                            <p className={tw`text-red-600`}>
                                Your username or password is incorrect (or
                                something else went wrong, but this is more
                                likely)
                            </p>
                        ))}

                    <div>
                        <button
                            type="submit"
                            className={tw`group relative w-full flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-green hover:bg-green-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500`}
                        >
                            <span
                                className={tw`absolute left-0 inset-y-0 flex items-center pl-3`}
                            >
                                <svg
                                    className={tw`h-5 w-5 text-white`}
                                    xmlns="http://www.w3.org/2000/svg"
                                    viewBox="0 0 20 20"
                                    fill="currentColor"
                                    aria-hidden="true"
                                >
                                    <path
                                        fillRule="evenodd"
                                        d="M5 9V7a5 5 0 0110 0v2a2 2 0 012 2v5a2 2 0 01-2 2H5a2 2 0 01-2-2v-5a2 2 0 012-2zm8-2v2H7V7a3 3 0 016 0z"
                                        clipRule="evenodd"
                                    />
                                </svg>
                            </span>
                            Sign in
                        </button>
                    </div>
                </form>
            </div>
        </div>
    );
}
