import Button from "components/button/Button";
import Address from "components/address/Address";

import { tw } from "twind";
import usePlacesAutocomplete, {
    getGeocode,
    getLatLng,
} from "use-places-autocomplete";
import { useMutation } from "@apollo/client";
import { ADD_VENUE, EDIT_VENUE } from "graphql/mutations";
import React, { useState } from "react";
import Input from "components/input/Input";
import { Venue } from "components/home/Home";
import { ReactComponent as CloseIcon } from "../dropdown/close.svg";

interface Props {
    activeVenue?: Venue;
    onSubmit(): void;
    onClose(): void;
}

export default function Modal({ onSubmit, onClose, activeVenue }: Props) {
    const {
        value,
        suggestions: { status, data },
        setValue,
        clearSuggestions,
    } = usePlacesAutocomplete({
        requestOptions: {
            /* Define search scope here */
        },
        debounce: 300,
        defaultValue: activeVenue?.addressText,
    });

    const [location, setLocation] = useState({ latitude: 0, longitude: 0 });
    const [name, setName] = useState(() => activeVenue?.name);
    const [link, setLink] = useState(() => activeVenue?.link);
    const [description, setDescription] = useState(
        () => activeVenue?.description
    );

    const handleInput = (e: any) => {
        // Update the keyword of the input element
        setValue(e.target.value);
    };

    const handleSelect = (props: { description: any }) => () => {
        // When user selects a place, we can replace the keyword without request data from API
        // by setting the second parameter to "false"
        setValue(props.description, false);
        clearSuggestions();

        // Get latitude and longitude via utility functions
        getGeocode({ address: props.description })
            .then((results) => getLatLng(results[0]))
            .then(({ lat, lng }) => {
                setLocation({ latitude: lat, longitude: lng });
            })
            .catch((error) => {
                console.log("😱 Error: ", error);
            });
    };

    const [addVenue] = useMutation(ADD_VENUE);
    const [editVenue] = useMutation(EDIT_VENUE);

    function onSubmitForm(event: any) {
        event.preventDefault();

        if (activeVenue?.id) {
            return editVenue({
                variables: {
                    id: activeVenue?.id,
                    addressText: value,
                    latitude: location.latitude || undefined,
                    longitude: location.longitude || undefined,
                    name,
                    link,
                    description,
                },
                refetchQueries: ["GetVenues"],
            }).then(onSubmit);
        }

        addVenue({
            variables: {
                addressText: value,
                latitude: location.latitude,
                longitude: location.longitude,
                name,
                organisationId: "f65c5e23-485b-429e-a3e1-06bda9a39c3b",
                link,
                description,
            },
            refetchQueries: ["GetVenues"],
        }).then(onSubmit);
    }

    return (
        <div className={tw`fixed z-10 inset-0 overflow-y-auto`}>
            <div
                className={tw`flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0`}
            >
                <form method="POST" onSubmit={onSubmitForm}>
                    <div
                        className={tw`fixed inset-0 transition-opacity`}
                        aria-hidden="true"
                    >
                        <div
                            className={tw`absolute inset-0 bg-gray-500 opacity-75`}
                        ></div>
                    </div>
                    <span
                        className={tw`hidden sm:inline-block sm:align-middle sm:h-screen`}
                        aria-hidden="true"
                    >
                        &#8203;
                    </span>
                    <div
                        className={tw`inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full`}
                        role="dialog"
                        aria-modal="true"
                        aria-labelledby="modal-headline"
                    >
                        <div
                            className={tw`bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4`}
                        >
                            <h3
                                className={tw`text-lg leading-6 font-medium w-full text-gray-900 flex justify-between`}
                                id="modal-headline"
                            >
                                Add venue
                                <button onClick={onClose}>
                                    <CloseIcon width="20" height="20" />
                                </button>
                            </h3>
                            <div className={tw`py-4`}>
                                <label
                                    htmlFor="first_name"
                                    className={tw`block text-sm font-medium text-gray-700`}
                                >
                                    Venue
                                </label>
                                <Input
                                    type="text"
                                    name="venue"
                                    value={name}
                                    onChange={(event: any) =>
                                        setName(event.target.value)
                                    }
                                />

                                <label
                                    htmlFor="address"
                                    className={tw`block text-sm font-medium text-gray-700`}
                                >
                                    Address
                                </label>
                                <Address
                                    handleInput={handleInput}
                                    handleSelect={handleSelect}
                                    data={data}
                                    value={value}
                                    status={status}
                                />

                                <label
                                    htmlFor="description"
                                    className={tw`block text-sm font-medium text-gray-700`}
                                >
                                    Description (optional)
                                </label>
                                <Input
                                    type="text"
                                    name="description"
                                    value={description}
                                    onChange={(event: any) =>
                                        setDescription(event.target.value)
                                    }
                                />
                            </div>

                            <label
                                htmlFor="first_name"
                                className={tw`block text-sm font-medium text-gray-700`}
                            >
                                Link (optional)
                            </label>
                            <Input
                                type="text"
                                name="link"
                                value={link}
                                onChange={(event: any) =>
                                    setLink(event.target.value)
                                }
                            />
                        </div>
                        <div
                            className={tw`bg-gray-50 px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse`}
                        >
                            <Button
                                disabled={
                                    (!Boolean(
                                        location?.latitude &&
                                            location?.longitude
                                    ) &&
                                        !Boolean(activeVenue?.addressText)) ||
                                    !Boolean(name)
                                }
                                type="submit"
                                variant="primary"
                            >
                                {Boolean(activeVenue?.id) ? "Save" : "Add"}
                            </Button>
                            <Button
                                type="button"
                                onClick={onClose}
                                variant="secondary"
                            >
                                Cancel
                            </Button>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    );
}
