import { useMutation } from "@apollo/client";
import { FILE_UPLOAD } from "graphql/mutations";
import React, { useEffect } from "react";

interface Props {
    previewUrl?: string;
    onChange(location: string): void;
    filter?: string;
}

export default function FileUpload({
    onChange,
    previewUrl,
    filter = "*",
}: Props) {
    const [uploadFile, { data }] = useMutation<{
        upload: { location: string };
    }>(FILE_UPLOAD);

    function onInputChange({
        currentTarget: { validity, files },
    }: React.ChangeEvent<HTMLInputElement>) {
        const file = files?.[0];
        if (file && validity.valid) {
            uploadFile({ variables: { file } });
        }
    }

    useEffect(() => {
        if (data?.upload?.location) {
            onChange(data?.upload?.location || "");
        }
    }, [onChange, data?.upload?.location]);

    return (
        <>
            Upload file:
            <div>
                <input
                    name="image"
                    onChange={onInputChange}
                    type="file"
                    accept={`image/${filter}`}
                />
                {previewUrl && (
                    <img
                        width="100"
                        height="100"
                        src={previewUrl}
                        alt="preview"
                    />
                )}
            </div>
        </>
    );
}
