import { useLazyQuery, useMutation } from "@apollo/client";
import Modal from "components/modal/Modal";
import Table from "components/table/Table";
import { GET_VENUES } from "graphql/queries";
import { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import Navigation from "components/navigation/Navigation";
import { DELETE_VENUE } from "graphql/mutations";

export interface Venue {
    name: string;
    description?: string;
    link?: string;
    addressText?: string;
    id: string;
    full_count?: number;
}

export default function Home() {
    const [getVenues, { data }] = useLazyQuery(GET_VENUES, {
        fetchPolicy: "no-cache",
        notifyOnNetworkStatusChange: true,
    });
    const [deleteVenue] = useMutation(DELETE_VENUE);
    const [activeVenue, setActiveVenue] = useState<Venue>();
    const [modalVisible, setModalVisible] = useState(false);
    const history = useHistory();
    const [selectedPage, setSelectedPage] = useState(1);
    const [searchText, setSearchText] = useState("");

    function onDelete(id: string) {
        deleteVenue({
            variables: { venueId: id },
            refetchQueries: ["GetVenues", "GetEvents"],
        });
    }

    function onEdit(venue: Venue) {
        setActiveVenue(venue);
        setModalVisible(true);
    }

    function onChangeText(text: string) {
        setSearchText(text);
    }

    useEffect(() => {
        getVenues?.({
            variables: {
                limit: 10,
                search: searchText,
                offset: (selectedPage - 1) * 10,
            },
        });
    }, [selectedPage, getVenues, searchText]);

    return (
        <>
            <Navigation />

            <Table
                searchText={searchText}
                onChangeText={onChangeText}
                title={"Venues"}
                alertTitle="Are you sure?"
                alertDescription="Deleting this venue will also delete any events within this venue."
                columns={[
                    { name: "Name", value: "name" },
                    { name: "Address", value: "addressText" },
                    { name: "Link", value: "link" },
                ]}
                offset={selectedPage}
                onPageChange={setSelectedPage}
                limit={10}
                recordCount={data?.venues?.[0]?.full_count}
                onAdd={() => setModalVisible(true)}
                onEdit={onEdit}
                onClick={(row) => history.push(`/venues/${row.id}/events`)}
                data={data?.venues}
                onDelete={onDelete}
            />
            {modalVisible && (
                <Modal
                    activeVenue={activeVenue}
                    onSubmit={() => {
                        setActiveVenue(undefined);
                        setModalVisible(false);
                    }}
                    onClose={() => {
                        setActiveVenue(undefined);
                        setModalVisible(false);
                    }}
                />
            )}
        </>
    );
}
