import { gql } from "@apollo/client";

export const ADD_VENUE = gql`
    mutation AddVenue(
        $name: String!
        $longitude: Float!
        $latitude: Float!
        $addressText: String!
        $description: String
        $organisationId: String!
        $link: String
    ) {
        addVenue(
            venue: {
                name: $name
                longitude: $longitude
                latitude: $latitude
                addressText: $addressText
                description: $description
                organisationId: $organisationId
                link: $link
            }
        ) {
            id
        }
    }
`;

export const ADD_PIN = gql`
    mutation AddPin(
        $name: String!
        $longitude: Float!
        $latitude: Float!
        $addressText: String!
        $description: String
        $color: String!
        $svgUrl: String!
        $link: String
        $buttonText: String!
    ) {
        addPin(
            pin: {
                name: $name
                longitude: $longitude
                latitude: $latitude
                addressText: $addressText
                description: $description
                color: $color
                link: $link
                svgUrl: $svgUrl
                buttonText: $buttonText
            }
        ) {
            id
        }
    }
`;

export const EDIT_VENUE = gql`
    mutation EditVenue(
        $id: String!
        $name: String!
        $longitude: Float
        $latitude: Float
        $addressText: String!
        $description: String
        $organisationId: String
        $link: String
    ) {
        editVenue(
            venue: {
                id: $id
                name: $name
                longitude: $longitude
                latitude: $latitude
                addressText: $addressText
                description: $description
                organisationId: $organisationId
                link: $link
            }
        ) {
            id
        }
    }
`;

export const EDIT_PIN = gql`
    mutation EditPin(
        $id: String!
        $name: String!
        $longitude: Float
        $latitude: Float
        $addressText: String!
        $description: String
        $color: String
        $link: String
        $svgUrl: String
        $buttonText: String!
    ) {
        editPin(
            pin: {
                id: $id
                name: $name
                longitude: $longitude
                latitude: $latitude
                addressText: $addressText
                description: $description
                color: $color
                link: $link
                svgUrl: $svgUrl
                buttonText: $buttonText
            }
        ) {
            id
        }
    }
`;

export const DELETE_VENUE = gql`
    mutation DeleteVenue($venueId: String!) {
        deleteVenue(venueId: $venueId)
    }
`;

export const DELETE_ANNOUNCEMENT = gql`
    mutation DeleteAnnouncement($announcementId: String!) {
        deleteAnnouncement(announcementId: $announcementId)
    }
`;

export const DELETE_PIN = gql`
    mutation DeletePin($pinId: String!) {
        deletePin(pinId: $pinId)
    }
`;

export const DELETE_EVENT = gql`
    mutation DeleteEvent($eventId: String!) {
        deleteEvent(eventId: $eventId)
    }
`;

export const DELETE_CATEGORY = gql`
    mutation DeleteCategory($categoryId: String!) {
        deleteCategory(categoryId: $categoryId)
    }
`;

export const EDIT_EVENT = gql`
    mutation editEvent(
        $id: String!
        $name: String
        $description: String
        $venueId: String
        $categoryId: String
        $startDate: DateTime
        $endDate: DateTime
        $link: String
        $imageUrl: String
        $ticketPurchaseUrl: String
        $affiliateUrl: String
        $affiliateText: String
        $customPinColor: String
        $affiliateSvgUrl: String
        $affiliateButtonColor: String
        $affiliateSvgColor: String
        $supportText: String
        $supportSvgUrl: String
    ) {
        editEvent(
            event: {
                id: $id
                name: $name
                description: $description
                venueId: $venueId
                categoryId: $categoryId
                startDate: $startDate
                endDate: $endDate
                link: $link
                imageUrl: $imageUrl
                ticketPurchaseUrl: $ticketPurchaseUrl
                affiliateUrl: $affiliateUrl
                affiliateText: $affiliateText
                customPinColor: $customPinColor
                affiliateSvgUrl: $affiliateSvgUrl
                affiliateButtonColor: $affiliateButtonColor
                affiliateSvgColor: $affiliateSvgColor
                supportText: $supportText
                supportSvgUrl: $supportSvgUrl
            }
        ) {
            id
        }
    }
`;

export const EDIT_ANNOUNCEMENT = gql`
    mutation editAnnouncement($id: String!, $text: String!) {
        editAnnouncement(editAnnouncementInput: { id: $id, text: $text }) {
            id
        }
    }
`;

export const ADD_ANNOUNCEMENT = gql`
    mutation addAnnouncement($text: String!) {
        addAnnouncement(addAnnouncementInput: { text: $text }) {
            id
        }
    }
`;

export const ADD_EVENT = gql`
    mutation AddEvent(
        $name: String!
        $categoryId: String!
        $venueId: String!
        $startDate: DateTime!
        $endDate: DateTime!
        $description: String
        $imageUrl: String
        $link: String
        $ticketPurchaseUrl: String
        $affiliateUrl: String
        $affiliateText: String
        $customPinColor: String
        $affiliateSvgUrl: String
        $affiliateButtonColor: String
        $affiliateSvgColor: String
        $supportText: String
        $supportSvgUrl: String
    ) {
        addEvent(
            event: {
                name: $name
                categoryId: $categoryId
                venueId: $venueId
                startDate: $startDate
                endDate: $endDate
                description: $description
                imageUrl: $imageUrl
                link: $link
                ticketPurchaseUrl: $ticketPurchaseUrl
                affiliateUrl: $affiliateUrl
                affiliateText: $affiliateText
                customPinColor: $customPinColor
                affiliateSvgUrl: $affiliateSvgUrl
                affiliateButtonColor: $affiliateButtonColor
                affiliateSvgColor: $affiliateSvgColor
                supportText: $supportText
                supportSvgUrl: $supportSvgUrl
            }
        ) {
            id
        }
    }
`;

export const EDIT_CATEGORY = gql`
    mutation EditCategory(
        $id: String!
        $name: String
        $imageUrl: String
        $svgUrl: String
    ) {
        editCategory(
            category: {
                id: $id
                name: $name
                imageUrl: $imageUrl
                svgUrl: $svgUrl
            }
        ) {
            id
        }
    }
`;

export const ADD_CATEGORY = gql`
    mutation AddCategory($name: String!, $imageUrl: String, $svgUrl: String!) {
        addCategory(
            category: { name: $name, imageUrl: $imageUrl, svgUrl: $svgUrl }
        ) {
            id
        }
    }
`;

export const ADD_USER = gql`
    mutation AddUser($email: String!, $password: String!, $role: String!) {
        addUser(user: { email: $email, password: $password, role: $role }) {
            id
        }
    }
`;

export const LOGIN = gql`
    mutation Login($email: String!, $password: String!) {
        login(loginInput: { email: $email, password: $password })
    }
`;

export const LOGOUT = gql`
    mutation Logout {
        logout
    }
`;

export const REFRESH_TOKEN = gql`
    mutation RefreshToken {
        refresh
    }
`;

export const FILE_UPLOAD = gql`
    mutation upload($file: Upload!) {
        upload(file: $file) {
            location
        }
    }
`;

export const CSV_UPLOAD = gql`
    mutation importCsv($file: Upload!) {
        importCsv(file: $file) {
            success
            errors
        }
    }
`;
