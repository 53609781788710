import Button from "components/button/Button";

import { tw } from "twind";

import { useMutation } from "@apollo/client";
import { ADD_CATEGORY, EDIT_CATEGORY } from "graphql/mutations";
import React, { useState } from "react";
import Input from "components/input/Input";
import { Category } from "components/category-dropdown/CategoryDropdown";
import { ReactComponent as CloseIcon } from "../dropdown/close.svg";
import MediaLibrary from "components/file-upload/MediaLibrary";

interface Props {
    activeCategory?: Category;
    onSubmit(): void;
    onClose(): void;
}

export default function Modal({ onSubmit, onClose, activeCategory }: Props) {
    const [addCategory] = useMutation(ADD_CATEGORY);
    const [editCategory] = useMutation(EDIT_CATEGORY);
    const [name, setName] = useState(() => activeCategory?.name || "");

    const [svgUrl, setSvgUrl] = useState<string | undefined>(
        () => activeCategory?.svgUrl
    );

    const [imageUrl, setImageUrl] = useState<string | undefined>(
        () => activeCategory?.imageUrl
    );

    function onSubmitForm(event: any) {
        event.preventDefault();

        if (activeCategory?.id) {
            return editCategory({
                variables: {
                    id: activeCategory.id,
                    name,
                    svgUrl,
                    imageUrl,
                },
                refetchQueries: ["GetCategories"],
            }).then(onSubmit);
        }

        addCategory({
            variables: {
                name,
                svgUrl,
                imageUrl,
            },
            refetchQueries: ["GetCategories"],
        }).then(onSubmit);
    }

    return (
        <div className={tw`fixed z-10 inset-0 overflow-y-auto`}>
            <div
                className={tw`flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0`}
            >
                <form method="POST" onSubmit={onSubmitForm}>
                    <div
                        className={tw`fixed inset-0 transition-opacity`}
                        aria-hidden="true"
                    >
                        <div
                            className={tw`absolute inset-0 bg-gray-500 opacity-75`}
                        ></div>
                    </div>
                    <span
                        className={tw`hidden sm:inline-block sm:align-middle sm:h-screen`}
                        aria-hidden="true"
                    >
                        &#8203;
                    </span>
                    <div
                        className={tw`inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full`}
                        role="dialog"
                        aria-modal="true"
                        aria-labelledby="modal-headline"
                    >
                        <div
                            className={tw`bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4`}
                        >
                            <h3
                                className={tw`text-lg leading-6 font-medium text-gray-900 flex justify-between`}
                                id="modal-headline"
                            >
                                {activeCategory?.id ? "Edit" : "Add"} category
                                <button onClick={onClose}>
                                    <CloseIcon width="20" height="20" />
                                </button>
                            </h3>
                            <div className={tw`py-4`}>
                                <label
                                    htmlFor="first_name"
                                    className={tw`block text-sm font-medium text-gray-700`}
                                >
                                    Name
                                </label>
                                <Input
                                    type="text"
                                    name="name"
                                    value={name}
                                    onChange={(event: any) =>
                                        setName(event.target.value)
                                    }
                                    className={tw`mt-1 focus:ring-indigo-500 my-2 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md`}
                                />
                                <label
                                    htmlFor="svg"
                                    className={tw`block text-sm font-medium text-gray-700`}
                                >
                                    Svg
                                </label>
                                <MediaLibrary
                                    filter="svg"
                                    previewUrl={svgUrl}
                                    onChange={setSvgUrl}
                                />

                                <label
                                    htmlFor="image"
                                    className={tw`block text-sm font-medium text-gray-700`}
                                >
                                    Default image
                                </label>
                                <MediaLibrary
                                    previewUrl={imageUrl}
                                    onChange={setImageUrl}
                                />
                            </div>
                        </div>
                        <div
                            className={tw`bg-gray-50 px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse`}
                        >
                            <Button
                                disabled={!name || !svgUrl}
                                type="submit"
                                variant="primary"
                            >
                                {activeCategory?.id ? "Save" : "Add"}
                            </Button>
                            <Button
                                type="button"
                                onClick={onClose}
                                variant="secondary"
                            >
                                Cancel
                            </Button>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    );
}
