import { useQuery } from "@apollo/client";
import FileUpload from "components/file-upload/FileUpload";
import Navigation from "components/navigation/Navigation";
import Search from "components/search/Search";
import Footer from "components/table/Footer";
import { GET_MEDIA } from "graphql/queries";
import React, { useEffect, useState } from "react";
import { tw } from "twind";
import MediaItem, { Media as MediaType } from "./MediaItem";

export default function Media({
    includeNavigation = true,
    onSelect,
    filter,
}: {
    includeNavigation?: boolean;
    onSelect?(media: MediaType): void;
    filter?: string;
}) {
    const [selectedPage, setSelectedPage] = useState(1);
    const [limit, setLimit] = useState(20);

    const [searchText, onChangeSearchText] = useState<string | undefined>("");

    const { data, loading, error, refetch } = useQuery<{
        media: { full_count: number; media: MediaType[] };
    }>(GET_MEDIA, {
        variables: {
            offset: selectedPage - 1,
            limit: limit,
            filter,
        },
    });

    function onFileUpload() {
        refetch({ offset: selectedPage - 1, limit, search: searchText });
    }

    useEffect(() => {
        refetch({ search: searchText, offset: selectedPage - 1, limit });
    }, [searchText, refetch, selectedPage, limit]);

    function onPageChange(page: number) {
        setSelectedPage(page);
    }

    return (
        <>
            {includeNavigation && <Navigation />}
            <div className={tw`max-w-7xl mx-auto py-8 sm:px-6 lg:px-8`}>
                <div className={tw`flex flex-col`}>
                    <div
                        className={tw`-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8`}
                    >
                        <div
                            className={tw`py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8`}
                        >
                            <div>
                                <Search
                                    text={searchText ?? ""}
                                    onChangeText={onChangeSearchText}
                                />

                                <FileUpload
                                    filter={filter}
                                    onChange={onFileUpload}
                                />
                            </div>

                            <br />
                            <div
                                className={tw`container grid grid-cols-10 gap-2 mx-auto`}
                            >
                                {data?.media?.media?.map((media: MediaType) => (
                                    <MediaItem
                                        key={media.id}
                                        onClick={onSelect}
                                        {...media}
                                    />
                                ))}
                            </div>
                            <Footer
                                offset={selectedPage}
                                limit={limit}
                                recordCount={data?.media?.full_count}
                                onPageChange={onPageChange}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}
