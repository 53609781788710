import Button from "components/button/Button";
import Address from "components/address/Address";

import { tw } from "twind";
import usePlacesAutocomplete, {
    getGeocode,
    getLatLng,
} from "use-places-autocomplete";
import { useMutation } from "@apollo/client";
import { ADD_PIN, EDIT_PIN } from "graphql/mutations";
import React, { useState } from "react";
import Input from "components/input/Input";
import { ReactComponent as CloseIcon } from "../dropdown/close.svg";
import { Pin } from "components/pins/Pins";
import MarkdownEditor from "components/markdown-editor/MarkdownEditor";
import MediaLibrary from "components/file-upload/MediaLibrary";

interface Props {
    activePin?: Pin;
    onSubmit(): void;
    onClose(): void;
}

export default function Modal({ onSubmit, onClose, activePin }: Props) {
    const {
        value,
        suggestions: { status, data },
        setValue,
        clearSuggestions,
    } = usePlacesAutocomplete({
        requestOptions: {
            /* Define search scope here */
        },
        debounce: 300,
        defaultValue: activePin?.addressText,
    });

    const [location, setLocation] = useState({ latitude: 0, longitude: 0 });
    const [name, setName] = useState(() => activePin?.name);
    const [link, setLink] = useState(() => activePin?.link);
    const [description, setDescription] = useState(
        () => activePin?.description
    );
    const [buttonText, setButtonText] = useState(() => activePin?.buttonText);

    const [color, setColor] = useState(() => activePin?.color);
    const handleInput = (e: any) => {
        // Update the keyword of the input element
        setValue(e.target.value);
    };
    const [svgUrl, setSvgUrl] = useState(() => activePin?.svgUrl);

    const handleSelect = (props: { description: any }) => () => {
        // When user selects a place, we can replace the keyword without request data from API
        // by setting the second parameter to "false"
        setValue(props.description, false);
        clearSuggestions();

        // Get latitude and longitude via utility functions
        getGeocode({ address: props.description })
            .then((results) => getLatLng(results[0]))
            .then(({ lat, lng }) => {
                setLocation({ latitude: lat, longitude: lng });
            })
            .catch((error) => {
                console.log("😱 Error: ", error);
            });
    };

    const [addPin] = useMutation(ADD_PIN);
    const [editPin] = useMutation(EDIT_PIN);

    function onSubmitForm(event: any) {
        event.preventDefault();

        if (activePin?.id) {
            return editPin({
                variables: {
                    id: activePin?.id,
                    addressText: value,
                    latitude: location.latitude || undefined,
                    longitude: location.longitude || undefined,
                    color,
                    name,
                    link,
                    svgUrl,
                    description,
                    buttonText,
                },
                refetchQueries: ["GetPins"],
            }).then(onSubmit);
        }

        addPin({
            variables: {
                addressText: value,
                latitude: location.latitude,
                longitude: location.longitude,
                name,
                color,
                link,
                svgUrl,
                buttonText,
                description,
            },
            refetchQueries: ["GetPins"],
        }).then(onSubmit);
    }

    console.log(
        !Boolean(location?.latitude && location?.longitude) &&
            !Boolean(activePin?.addressText),
        !Boolean(name),
        !Boolean(color),
        !Boolean(svgUrl)
    );
    return (
        <div className={tw`fixed z-10 inset-0 overflow-y-auto`}>
            <div
                className={tw`flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0`}
            >
                <form method="POST" onSubmit={onSubmitForm}>
                    <div
                        className={tw`fixed inset-0 transition-opacity`}
                        aria-hidden="true"
                    >
                        <div
                            className={tw`absolute inset-0 bg-gray-500 opacity-75`}
                        ></div>
                    </div>
                    <span
                        className={tw`hidden sm:inline-block sm:align-middle sm:h-screen`}
                        aria-hidden="true"
                    >
                        &#8203;
                    </span>
                    <div
                        className={tw`inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full`}
                        role="dialog"
                        aria-modal="true"
                        aria-labelledby="modal-headline"
                    >
                        <div
                            className={tw`bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4`}
                        >
                            <h3
                                className={tw`text-lg leading-6 font-medium w-full text-gray-900 flex justify-between`}
                                id="modal-headline"
                            >
                                Add pin
                                <button onClick={onClose}>
                                    <CloseIcon width="20" height="20" />
                                </button>
                            </h3>
                            <div className={tw`py-4`}>
                                <label
                                    htmlFor="first_name"
                                    className={tw`block text-sm font-medium text-gray-700`}
                                >
                                    Title
                                </label>
                                <Input
                                    type="text"
                                    name="name"
                                    value={name}
                                    onChange={(event: any) =>
                                        setName(event.target.value)
                                    }
                                />

                                <label
                                    htmlFor="address"
                                    className={tw`block text-sm font-medium text-gray-700`}
                                >
                                    Address
                                </label>
                                <Address
                                    handleInput={handleInput}
                                    handleSelect={handleSelect}
                                    data={data}
                                    value={value}
                                    status={status}
                                />

                                <label
                                    htmlFor="description"
                                    className={tw`block text-sm font-medium text-gray-700`}
                                >
                                    Description (optional)
                                </label>
                                <MarkdownEditor
                                    markdown={description || ""}
                                    onChange={setDescription}
                                />
                            </div>

                            <label
                                htmlFor="first_name"
                                className={tw`block text-sm font-medium text-gray-700`}
                            >
                                Button Text (optional)
                            </label>
                            <Input
                                type="text"
                                name="link"
                                value={buttonText}
                                onChange={(event: any) =>
                                    setButtonText(event.target.value)
                                }
                            />

                            <label
                                htmlFor="first_name"
                                className={tw`block text-sm font-medium text-gray-700`}
                            >
                                Link (optional)
                            </label>
                            <Input
                                type="text"
                                name="link"
                                value={link}
                                onChange={(event: any) =>
                                    setLink(event.target.value)
                                }
                            />
                            <label
                                htmlFor="first_name"
                                className={tw`block text-sm font-medium text-gray-700`}
                            >
                                Pin background color
                            </label>
                            <Input
                                type="color"
                                name="color"
                                value={color}
                                onChange={(event: any) =>
                                    setColor(event.target.value)
                                }
                            />
                            <label
                                htmlFor="svg"
                                className={tw`block text-sm font-medium text-gray-700`}
                            >
                                Svg
                            </label>
                            <MediaLibrary
                                previewUrl={svgUrl}
                                filter="svg"
                                onChange={setSvgUrl}
                            />
                        </div>
                        <div
                            className={tw`bg-gray-50 px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse`}
                        >
                            <Button
                                disabled={
                                    (!Boolean(
                                        location?.latitude &&
                                            location?.longitude
                                    ) &&
                                        !Boolean(activePin?.addressText)) ||
                                    !Boolean(name) ||
                                    !Boolean(color) ||
                                    !Boolean(svgUrl)
                                }
                                type="submit"
                                variant="primary"
                            >
                                {Boolean(activePin?.id) ? "Save" : "Add"}
                            </Button>
                            <Button
                                type="button"
                                onClick={onClose}
                                variant="secondary"
                            >
                                Cancel
                            </Button>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    );
}
