import React, { useState } from "react";
import { tw } from "twind";

export default function Input({
    onChange,
    ...props
}: React.InputHTMLAttributes<HTMLInputElement>) {
    const [color, setColor] = useState(
        props.type === "color" ? props.value : ""
    );

    function onColorChange(event: any) {
        setColor(event.target.value);
        onChange?.(event);
    }

    return (
        <input
            {...props}
            type={props.type === "color" ? "text" : props.type}
            onChange={props.type === "color" ? onColorChange : onChange}
            className={tw`border border-gray-300 bg-white text-gray-900 appearance-none block w-full rounded-md py-2 px-3 focus:border-blue-500 focus:outline-none`}
            style={{
                borderColor: color ? (color as string) : undefined,
                borderWidth: color ? 5 : undefined,
            }}
        />
    );
}
