import NumberDropdown from "components/number-dropdown/NumberDropdown";
import React from "react";
import { tw } from "twind";

interface Props {
    offset?: number;
    limit?: number;
    recordCount?: number;
    onPageChange?(page: number): void;
}

export default function Footer({
    offset,
    limit,
    recordCount,
    onPageChange,
}: Props) {
    return (
        <div
            className={tw`bg-white px-4 py-3 flex items-center justify-between border-t border-gray-200 sm:px-6`}
        >
            <div
                className={tw`hidden sm:flex-1 sm:flex sm:items-center sm:justify-between`}
            >
                <div>
                    <p className={tw`text-sm text-gray-700`}>
                        Showing
                        <span className={tw`font-medium`}>
                            {" "}
                            {Math.max(
                                ((offset || 1) - 1) * (limit || 10),
                                1
                            )}{" "}
                        </span>
                        to
                        <span className={tw`font-medium`}>
                            {" "}
                            {offset !== undefined && limit !== undefined
                                ? Math.min(offset * limit, recordCount || 0)
                                : undefined}{" "}
                        </span>
                        of
                        <span className={tw`font-medium`}> {recordCount} </span>
                        results
                    </p>
                </div>
                <div>
                    Page:
                    <NumberDropdown
                        selected={offset || 1}
                        range={Math.ceil((recordCount || 1) / (limit || 1))}
                        setSelected={onPageChange}
                    />
                </div>
            </div>
        </div>
    );
}
