import { useLazyQuery, useMutation } from "@apollo/client";
import Modal from "components/announcement-modal/Modal";
import Table from "components/table/Table";
import { GET_ANNOUNCEMENTS } from "graphql/queries";
import { useEffect, useState } from "react";
import Navigation from "components/navigation/Navigation";
import { DELETE_ANNOUNCEMENT } from "graphql/mutations";

export interface Announcement {
    id: string;
    text: string;
}

export default function Announcements() {
    const [getAnnouncements, { data }] = useLazyQuery(GET_ANNOUNCEMENTS, {
        fetchPolicy: "no-cache",
        notifyOnNetworkStatusChange: true,
    });

    const [deleteAnnouncement] = useMutation(DELETE_ANNOUNCEMENT);

    const [activeAnnouncement, setActiveAnnouncement] =
        useState<Announcement>();

    const [modalVisible, setModalVisible] = useState(false);
    const [selectedPage, setSelectedPage] = useState(1);
    const [searchText, setSearchText] = useState("");

    function onDelete(id: string) {
        deleteAnnouncement({
            variables: { announcementId: id },
            refetchQueries: ["GetAnnouncements"],
        });
    }

    function onEdit(announcement: Announcement) {
        setActiveAnnouncement(announcement);
        setModalVisible(true);
    }

    function onChangeText(text: string) {
        setSearchText(text);
    }

    useEffect(() => {
        getAnnouncements?.({
            variables: {
                limit: 10,
                search: searchText,
                offset: (selectedPage - 1) * 10,
            },
        });
    }, [selectedPage, getAnnouncements, searchText]);

    return (
        <>
            <Navigation />

            <Table
                searchText={searchText}
                onChangeText={onChangeText}
                title={"Announcements"}
                alertTitle="Are you sure?"
                alertDescription="Yo"
                columns={[
                    {
                        name: "Text",
                        value: "text",
                        maxLength: 50,
                    },
                    { name: "Address", value: "addressText" },
                    { name: "Link", value: "link" },
                ]}
                offset={selectedPage}
                onPageChange={setSelectedPage}
                limit={10}
                recordCount={data?.announcements?.[0]?.full_count}
                onAdd={() => setModalVisible(true)}
                onEdit={onEdit}
                data={data?.announcements}
                onDelete={onDelete}
            />
            {modalVisible && (
                <Modal
                    activeAnnouncement={activeAnnouncement}
                    onSubmit={() => {
                        setActiveAnnouncement(undefined);
                        setModalVisible(false);
                    }}
                    onClose={() => {
                        setActiveAnnouncement(undefined);
                        setModalVisible(false);
                    }}
                />
            )}
        </>
    );
}
