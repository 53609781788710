import { useMutation, useQuery } from "@apollo/client";
import {
    Categories,
    Category,
} from "components/category-dropdown/CategoryDropdown";
import Modal from "components/category-modal/CategoryModal";
import Table from "components/table/Table";
import { GET_CATEGORIES } from "graphql/queries";
import { useState } from "react";
import Navigation from "components/navigation/Navigation";
import { DELETE_CATEGORY } from "graphql/mutations";

export default function Home() {
    const { data } = useQuery<{ categories: Categories }>(GET_CATEGORIES);
    const [modalVisible, setModalVisible] = useState(false);
    const [activeCategory, setActiveCategory] = useState<Category>();

    const [deleteCategory] = useMutation(DELETE_CATEGORY);

    function onDelete(id: string) {
        deleteCategory({
            variables: { categoryId: id },
            refetchQueries: ["GetCategories", "GetEvents"],
        });
    }

    function onEdit(category: Category) {
        setActiveCategory(category);
        setModalVisible(true);
    }

    return (
        <>
            <Navigation />
            <Table
                title="Categories"
                onDelete={onDelete}
                onEdit={onEdit}
                alertTitle="Are you sure?"
                alertDescription="Deleting this category will also delete any events with this category."
                columns={[
                    { name: "Name", value: "name" },
                    {
                        name: "Icon",
                        value: "svgUrl",
                        render: (svg: string) => <img width={24} src={svg} />,
                    },
                    {
                        name: "Image",
                        value: "imageUrl",
                        render: (imageUrl: string) => (
                            <img width={86} src={imageUrl} alt={imageUrl} />
                        ),
                    },
                ]}
                onAdd={() => setModalVisible(true)}
                data={data?.categories || []}
            />
            {modalVisible && (
                <Modal
                    activeCategory={activeCategory}
                    onSubmit={() => {
                        setActiveCategory(undefined);
                        setModalVisible(false);
                    }}
                    onClose={() => {
                        setActiveCategory(undefined);
                        setModalVisible(false);
                    }}
                />
            )}
        </>
    );
}
