import Button from "components/button/Button";

import { tw } from "twind";

import { useMutation } from "@apollo/client";
import { ADD_USER } from "graphql/mutations";
import React, { useState } from "react";
import Input from "components/input/Input";
import { ReactComponent as CloseIcon } from "../dropdown/close.svg";

interface Props {
    onSubmit(): void;
    onClose(): void;
}

export default function Modal({ onSubmit, onClose }: Props) {
    const [addCategory] = useMutation(ADD_USER);
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");

    function onSubmitForm(event: any) {
        event.preventDefault();

        addCategory({
            variables: {
                email,
                password,
                role: "admin",
            },
            refetchQueries: ["GetUsers"],
        }).then(onSubmit);
    }

    return (
        <div className={tw`fixed z-10 inset-0 overflow-y-auto`}>
            <div
                className={tw`flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0`}
            >
                <form method="POST" onSubmit={onSubmitForm}>
                    <div
                        className={tw`fixed inset-0 transition-opacity`}
                        aria-hidden="true"
                    >
                        <div
                            className={tw`absolute inset-0 bg-gray-500 opacity-75`}
                        ></div>
                    </div>
                    <span
                        className={tw`hidden sm:inline-block sm:align-middle sm:h-screen`}
                        aria-hidden="true"
                    >
                        &#8203;
                    </span>
                    <div
                        className={tw`inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full`}
                        role="dialog"
                        aria-modal="true"
                        aria-labelledby="modal-headline"
                    >
                        <div
                            className={tw`bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4`}
                        >
                            <h3
                                className={tw`text-lg leading-6 font-medium text-gray-900 flex justify-between`}
                                id="modal-headline"
                            >
                                Add user
                                <button onClick={onClose}>
                                    <CloseIcon width="20" height="20" />
                                </button>
                            </h3>
                            <div className={tw`py-4`}>
                                <label
                                    htmlFor="email"
                                    className={tw`block text-sm font-medium text-gray-700`}
                                >
                                    Email
                                </label>
                                <Input
                                    type="email"
                                    name="email"
                                    value={email}
                                    autoComplete="off"
                                    onChange={(event: any) =>
                                        setEmail(event.target.value)
                                    }
                                />
                                <label
                                    htmlFor="password"
                                    className={tw`block text-sm font-medium text-gray-700`}
                                >
                                    password
                                </label>
                                <Input
                                    type="password"
                                    name="password"
                                    value={password}
                                    autoComplete="off"
                                    onChange={(event: any) =>
                                        setPassword(event.target.value)
                                    }
                                />
                            </div>
                        </div>
                        <div
                            className={tw`bg-gray-50 px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse`}
                        >
                            <Button
                                disabled={!email || !password}
                                type="submit"
                                variant="primary"
                            >
                                Add
                            </Button>
                            <Button
                                type="button"
                                onClick={onClose}
                                variant="secondary"
                            >
                                Cancel
                            </Button>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    );
}
