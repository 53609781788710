import { useQuery } from "@apollo/client";
import { ME } from "graphql/queries";
import { useEffect } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { tw } from "twind";

interface Props {
    children: React.ReactElement;
}

export default function Background({ children }: Props) {
    const { data, loading } = useQuery(ME);
    const isLoggedIn = Boolean(data?.me?.id);
    const history = useHistory();
    const location = useLocation();

    useEffect(() => {
        if (loading) {
            return;
        }

        if (!isLoggedIn && location.pathname !== "/login") {
            return history.push("/login");
        }
    }, [isLoggedIn, location.pathname, history, loading]);

    return <div className={tw`bg-gray-100 h-screen`}>{children}</div>;
}
