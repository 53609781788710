import { ApolloClient, fromPromise, InMemoryCache } from "@apollo/client";
import { createUploadLink } from "apollo-upload-client";
import { onError } from "@apollo/client/link/error";
import { REFRESH_TOKEN } from "./mutations";

const httpLink = createUploadLink({
    uri: `${process.env.REACT_APP_GRAPHQL_URI}/graphql`,
    credentials: "include",
});

const getNewToken = async () => {
    const newToken = await client.mutate({ mutation: REFRESH_TOKEN });
    return newToken.data?.refresh;
};

const errorLink = onError(
    ({ graphQLErrors, networkError, operation, forward }) => {
        if (!graphQLErrors) {
            forward(operation);
        }

        const hasUnauthenticatedError = graphQLErrors?.some((err) =>
            err?.message.startsWith("Access denied!")
        );

        if (hasUnauthenticatedError) {
            return fromPromise(
                getNewToken().catch((error) => {
                    console.error(error);
                    window.location.reload();
                })
            ).flatMap((refreshedToken: boolean) => {
                return forward(operation);
            });
        } else {
            return forward(operation);
        }
    }
);

const client = new ApolloClient({
    link: errorLink.concat(httpLink),
    cache: new InMemoryCache(),
});

export default client;
