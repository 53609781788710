import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import client from "graphql/client";
import { ApolloProvider } from "@apollo/client";

import Login from "components/login/Login";
import Home from "components/home/Home";
import Background from "components/background/Background";
import Categories from "components/categories/Categories";

import { setup } from "twind";
import Events from "components/events/Events";
import Logout from "components/logout/Logout";
import Users from "components/users/Users";
import Pins from "components/pins/Pins";
import Announcements from "components/announcements/Announcements";
import Media from "components/media/Media";
import Upload from "components/upload/Upload";

setup({
    theme: {
        extend: {
            colors: { green: "#138701" },
        },
    },
});

function App() {
    return (
        <ApolloProvider client={client}>
            <Router>
                <Background>
                    <Switch>
                        <Route path="/logout">
                            <Logout />
                        </Route>
                        <Route path="/login">
                            <Login />
                        </Route>
                        <Route path="/users">
                            <Users />
                        </Route>
                        <Route path="/categories">
                            <Categories />
                        </Route>
                        <Route path="/venues/:venueId/events">
                            <Events />
                        </Route>
                        <Route path="/events">
                            <Events />
                        </Route>
                        <Route path="/pins">
                            <Pins />
                        </Route>
                        <Route path="/announcements">
                            <Announcements />
                        </Route>
                        <Route path="/media">
                            <Media />
                        </Route>
                        <Route path="/upload">
                            <Upload />
                        </Route>
                        <Route path="/">
                            <Home />
                        </Route>
                    </Switch>
                </Background>
            </Router>
        </ApolloProvider>
    );
}

export default App;
