import Button from "components/button/Button";
import Media from "components/media/Media";
import React, { useEffect, useReducer } from "react";
import { Media as MediaType } from "components/media/MediaItem";
import { tw } from "twind";
interface Props {
    previewUrl?: string;
    onChange(url: string): void;
    filter?: string;
}

export default function FileUpload({ onChange, previewUrl, filter }: Props) {
    const [isOpen, toggleIsOpen] = useReducer((open) => !open, false);

    const onSelect = (media: MediaType) => {
        onChange(media.location);
        toggleIsOpen();
    };

    if (!isOpen) {
        return (
            <>
                <Button onClick={toggleIsOpen} variant="primary" type="button">
                    Select Image
                </Button>
                <img
                    width="150"
                    height="150"
                    src={previewUrl}
                    className={tw`cover`}
                />
            </>
        );
    }

    return (
        <div style={{ border: "1px solid black" }}>
            <Media
                filter={filter}
                onSelect={onSelect}
                includeNavigation={false}
            />
        </div>
    );
}
