interface Props {
    selected: number;
    range: number;
    setSelected?(value: number): void;
}

export default function NumberDropdown({
    selected,
    range,
    setSelected,
}: Props) {
    function onChange(event: React.ChangeEvent<HTMLSelectElement>) {
        setSelected?.(parseInt(event.currentTarget.value, 10));
    }

    return (
        <select value={selected} onChange={onChange}>
            {range > 1 &&
                Array(range)
                    .fill(1)
                    .map((_, index) => (
                        <option value={index + 1}>{index + 1}</option>
                    ))}
        </select>
    );
}
