import { tw } from "twind";

interface Props {
    children: React.ReactElement | string;
    type: "button" | "reset" | "submit" | undefined;
    variant: string;
    disabled?: boolean;
    onClick?: React.MouseEventHandler<HTMLButtonElement>;
}

const style: { [key: string]: string } = {
    primary:
        "w-full inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-green hover:hover:bg-green-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm",
    secondary:
        "mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm",
    dangerous:
        "mt-3 w-full inline-flex justify-center rounded-md border border-red-600 shadow-sm px-4 py-2 bg-red-600 text-base font-medium text-white hover:bg-white hover:text-red-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm",
};

export default function Button({
    children,
    variant,
    disabled,
    ...props
}: Props) {
    return (
        <button
            className={tw(
                style[variant] +
                    (disabled ? " opacity-50 cursor-not-allowed" : "")
            )}
            disabled={disabled}
            {...props}
        >
            {children}
        </button>
    );
}
