import Button from "components/button/Button";

import { tw } from "twind";
import { useMutation } from "@apollo/client";
import { ADD_ANNOUNCEMENT, EDIT_ANNOUNCEMENT } from "graphql/mutations";
import React, { useState } from "react";
import Input from "components/input/Input";
import { ReactComponent as CloseIcon } from "../dropdown/close.svg";

import { Announcement } from "components/announcements/Announcements";
import MarkdownEditor from "components/markdown-editor/MarkdownEditor";

interface Props {
    activeAnnouncement?: Announcement;
    onSubmit(): void;
    onClose(): void;
}

export default function Modal({
    onSubmit,
    onClose,
    activeAnnouncement,
}: Props) {
    const [text, setText] = useState(() => activeAnnouncement?.text);

    const [addAnnouncement] = useMutation(ADD_ANNOUNCEMENT);
    const [editAnnouncement] = useMutation(EDIT_ANNOUNCEMENT);

    function onSubmitForm(event: any) {
        event.preventDefault();

        if (activeAnnouncement?.id) {
            return editAnnouncement({
                variables: {
                    id: activeAnnouncement?.id,
                    text,
                },
                refetchQueries: ["GetAnnouncements"],
            }).then(onSubmit);
        }

        addAnnouncement({
            variables: {
                text,
            },
            refetchQueries: ["GetAnnouncements"],
        }).then(onSubmit);
    }

    return (
        <div className={tw`fixed z-10 inset-0 overflow-y-auto`}>
            <div
                className={tw`flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0`}
            >
                <form method="POST" onSubmit={onSubmitForm}>
                    <div
                        className={tw`fixed inset-0 transition-opacity`}
                        aria-hidden="true"
                    >
                        <div
                            className={tw`absolute inset-0 bg-gray-500 opacity-75`}
                        ></div>
                    </div>
                    <span
                        className={tw`hidden sm:inline-block sm:align-middle sm:h-screen`}
                        aria-hidden="true"
                    >
                        &#8203;
                    </span>
                    <div
                        className={tw`inline-block align-bottom h-full w-full bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle  `}
                        role="dialog"
                        aria-modal="true"
                        aria-labelledby="modal-headline"
                    >
                        <div
                            className={tw`bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4`}
                        >
                            <h3
                                className={tw`text-lg leading-6 font-medium w-full text-gray-900 flex justify-between`}
                                id="modal-headline"
                            >
                                Add announcement
                                <button onClick={onClose}>
                                    <CloseIcon width="20" height="20" />
                                </button>
                            </h3>
                            <div className={tw`py-4`}>
                                <MarkdownEditor
                                    markdown={text || ""}
                                    onChange={setText}
                                />
                            </div>
                        </div>
                        <div
                            className={tw`bg-gray-50 px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse`}
                        >
                            <Button type="submit" variant="primary">
                                {Boolean(activeAnnouncement?.id)
                                    ? "Save"
                                    : "Add"}
                            </Button>
                            <Button
                                type="button"
                                onClick={onClose}
                                variant="secondary"
                            >
                                Cancel
                            </Button>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    );
}
