import { useLazyQuery, useMutation } from "@apollo/client";
import Modal from "components/pin-modal/Modal";
import Table from "components/table/Table";
import { GET_PINS } from "graphql/queries";
import { useEffect, useState } from "react";
import Navigation from "components/navigation/Navigation";
import { DELETE_PIN } from "graphql/mutations";

export interface Pin {
    id: string;
    color: string;
    name: string;
    description?: string;
    link?: string;
    addressText?: string;
    svgUrl: string;
    buttonText: string;
}

const limit = 10;

export default function Pins() {
    const [getPins, { data }] = useLazyQuery(GET_PINS, {
        fetchPolicy: "no-cache",
        notifyOnNetworkStatusChange: true,
    });

    const [deletePin] = useMutation(DELETE_PIN);

    const [activePin, setActivePin] = useState<Pin>();

    const [modalVisible, setModalVisible] = useState(false);
    const [selectedPage, setSelectedPage] = useState(1);
    const [searchText, setSearchText] = useState("");

    function onDelete(id: string) {
        deletePin({
            variables: { pinId: id },
            refetchQueries: ["GetPins"],
        });
    }

    function onEdit(pin: Pin) {
        setActivePin(pin);
        setModalVisible(true);
    }

    function onChangeText(text: string) {
        setSearchText(text);
    }

    useEffect(() => {
        getPins?.({
            variables: {
                limit,
                search: searchText,
                offset: (selectedPage - 1) * limit,
            },
        });
    }, [selectedPage, getPins, searchText]);

    return (
        <>
            <Navigation />

            <Table
                searchText={searchText}
                onChangeText={onChangeText}
                title={"Pins"}
                alertTitle="Are you sure?"
                alertDescription="Yo"
                columns={[
                    { name: "Name", value: "name" },
                    {
                        name: "Icon",
                        value: "svgUrl",
                        render: (svgUrl: string) => (
                            <img width={24} src={svgUrl} />
                        ),
                    },
                    { name: "Address", value: "addressText" },
                    { name: "Link", value: "link" },
                ]}
                offset={selectedPage}
                onPageChange={setSelectedPage}
                limit={limit}
                recordCount={data?.pins?.[0]?.full_count}
                onAdd={() => setModalVisible(true)}
                onEdit={onEdit}
                data={data?.pins}
                onDelete={onDelete}
            />
            {modalVisible && (
                <Modal
                    activePin={activePin}
                    onSubmit={() => {
                        setActivePin(undefined);
                        setModalVisible(false);
                    }}
                    onClose={() => {
                        setActivePin(undefined);
                        setModalVisible(false);
                    }}
                />
            )}
        </>
    );
}
