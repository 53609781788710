import { useLazyQuery, useMutation } from "@apollo/client";
import AddEventModal from "components/modal/AddEvent";
import Table from "components/table/Table";
import { useEffect, useState } from "react";
import Navigation from "components/navigation/Navigation";
import { GET_EVENTS, GET_VENUE } from "graphql/queries";
import { useParams } from "react-router-dom";
import { DELETE_EVENT } from "graphql/mutations";
import { Venue } from "components/venue-dropdown/VenueDropdown";

export interface Event {
    id: string;
    name: string;
    description?: string;
    venue: Venue;
    category: {
        id: string;
    };
    startDate?: string;
    endDate?: string;
    link?: string;
    imageUrl?: string;
    ticketPurchaseUrl?: string;
    full_count?: number;
    affiliateUrl?: string;
    affiliateText?: string;
    customPinColor?: string;
    affiliateSvgUrl?: string;
    affiliateButtonColor?: string;
    affiliateSvgColor?: string;
    supportText?: string;
    supportSvgUrl?: string;
}

export default function Events() {
    const [getEvents, { data: eventsData }] = useLazyQuery(GET_EVENTS, {
        fetchPolicy: "no-cache",
        notifyOnNetworkStatusChange: true,
    });
    const [getVenue, { data: venueData }] = useLazyQuery(GET_VENUE);
    const [deleteEvent] = useMutation(DELETE_EVENT);
    const [modalVisible, setModalVisible] = useState(false);
    const [importedOnly, setImportedOnly] = useState(false);
    const [includeOutdated, setIncludeOutdated] = useState(false);
    const { venueId } = useParams<{ venueId?: string }>();
    const [activeEvent, setActiveEvent] = useState<Event>();
    const [selectedPage, setSelectedPage] = useState(1);
    const [searchText, setSearchText] = useState("");

    useEffect(() => {
        getEvents({ variables: { venueId, limit: 10, offset: 0 } });
        if (venueId) {
            getVenue({ variables: { venueId } });
        }
    }, [venueId, getEvents, getVenue]);

    function onDelete(id: string) {
        deleteEvent({
            variables: { eventId: id },
            refetchQueries: ["GetEvents"],
        });
    }

    function onEdit(event: Event) {
        setActiveEvent(event);
        setModalVisible(true);
    }

    function onChangeText(text: string) {
        setSearchText(text);
    }

    useEffect(() => {
        getEvents?.({
            variables: {
                venueId,
                limit: 10,
                search: searchText,
                offset: (selectedPage - 1) * 10,
                importedOnly: venueId ? importedOnly : false,
                showOutdated: includeOutdated,
            },
        });
    }, [
        selectedPage,
        getEvents,
        venueId,
        searchText,
        importedOnly,
        includeOutdated,
    ]);

    const filters = [
        {
            text: "Show imported only",
            onChange: setImportedOnly,
            value: importedOnly,
        },
        {
            text: "Include outdated",
            onChange: setIncludeOutdated,
            value: includeOutdated,
        },
    ];

    return (
        <>
            <Navigation />
            <Table
                venueId={venueId}
                filters={filters}
                searchText={searchText}
                onChangeText={onChangeText}
                title={
                    venueData?.venue?.name
                        ? venueData?.venue?.name + " events"
                        : "Events"
                }
                alertTitle="Are you sure?"
                alertDescription="You will not be able to get it back!"
                onPageChange={setSelectedPage}
                onDelete={onDelete}
                onEdit={onEdit}
                offset={selectedPage}
                limit={10}
                recordCount={eventsData?.events?.[0]?.full_count}
                columns={[
                    { name: "Name", value: "name", maxLength: 25 },
                    { name: "Venue", value: "venue.name" },
                    { name: "Category", value: "category.name" },
                    {
                        name: "Starts",
                        value: "startDate",
                        render: (date: string) =>
                            new Date(date).toLocaleString(),
                    },
                    {
                        name: "Ends",
                        value: "endDate",
                        render: (date: string) =>
                            new Date(date).toLocaleString(),
                    },
                    {
                        name: "Imported",
                        value: "importedFile",
                        render: (importedFile: string) => (
                            <span title={importedFile}>
                                {importedFile ? "☑️" : ""}
                            </span>
                        ),
                        maxLength: 1,
                    },
                ]}
                onAdd={() => setModalVisible(true)}
                data={eventsData?.events}
            />
            {modalVisible && (
                <AddEventModal
                    activeEvent={activeEvent}
                    selectedVenueId={venueId}
                    onSubmit={() => {
                        setActiveEvent(undefined);
                        setModalVisible(false);
                    }}
                    onClose={() => {
                        setActiveEvent(undefined);
                        setModalVisible(false);
                    }}
                />
            )}
        </>
    );
}
