import { useMutation } from "@apollo/client";
import { LOGOUT } from "graphql/mutations";
import { useEffect } from "react";

export default function Logout() {
    const [logout, { data }] = useMutation(LOGOUT);

    useEffect(() => {
        logout();
    }, [logout]);

    useEffect(() => {
        if (data?.logout) {
            window.location.reload();
        }
    }, [data?.logout]);

    return <p>Logging you out...</p>;
}
