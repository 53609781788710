import Button from "components/button/Button";
import { tw } from "twind";
import { useMutation } from "@apollo/client";
import { ADD_EVENT, EDIT_EVENT } from "graphql/mutations";
import React, { useState } from "react";
import CategoryDropdown from "components/category-dropdown/CategoryDropdown";
import VenueDropdown from "components/venue-dropdown/VenueDropdown";
import Calendar from "react-calendar";
import TimePicker from "rc-time-picker";
import moment from "moment";
import "react-calendar/dist/Calendar.css";
import "rc-time-picker/assets/index.css";
import Input from "components/input/Input";
import { Event } from "components/events/Events";
import { ReactComponent as CloseIcon } from "../dropdown/close.svg";
import MediaLibrary from "components/file-upload/MediaLibrary";

interface Props {
    selectedVenueId?: string;
    activeEvent?: Event;
    onSubmit(): void;
    onClose(): void;
}

export default function Modal({
    onSubmit,
    onClose,
    selectedVenueId,
    activeEvent,
}: Props) {
    const [name, setName] = useState(() => activeEvent?.name);
    const [categoryId, setCategoryId] = useState(
        () => activeEvent?.category?.id
    );
    const [venueId, setVenueId] = useState(() => activeEvent?.venue?.id);
    const [addEvent] = useMutation(ADD_EVENT);
    const [editEvent] = useMutation(EDIT_EVENT);

    const [startDate, setStartDate] = useState(() =>
        activeEvent?.startDate ? new Date(activeEvent?.startDate) : new Date()
    );
    const [endDate, setEndDate] = useState(() =>
        activeEvent?.endDate ? new Date(activeEvent?.endDate) : new Date()
    );
    const [description, setDescription] = useState(
        () => activeEvent?.description
    );
    const [imageUrl, setImageUrl] = useState(() => activeEvent?.imageUrl);
    const [ticketPurchaseUrl, setTicketPurchaseUrl] = useState(
        () => activeEvent?.ticketPurchaseUrl
    );
    const [link, setLink] = useState(() => activeEvent?.link);
    const [affiliateUrl, setAffiliateUrl] = useState(
        () => activeEvent?.affiliateUrl
    );

    const [affiliateText, setAffiliateText] = useState(
        () => activeEvent?.affiliateText
    );

    const [affiliateButtonColor, setAffiliateButtonColor] = useState(
        () => activeEvent?.affiliateButtonColor
    );

    const [affiliateSvgUrl, setAffiliateSvgUrl] = useState(
        () => activeEvent?.affiliateSvgUrl
    );

    const [supportText, setSupportText] = useState(
        () => activeEvent?.supportText
    );

    const [supportSvgUrl, setSupportSvgUrl] = useState(
        () => activeEvent?.supportSvgUrl
    );

    const [affiliateSvgColor, setAffiliateSvgColor] = useState(
        () => activeEvent?.affiliateSvgColor
    );
    const [customPinColor, setCustomPinColor] = useState(
        () => activeEvent?.customPinColor
    );

    const [showAdvancedOptions, setShowAdvancedOptions] = useState(false);

    function onSubmitForm(event: any) {
        event.preventDefault();

        if (activeEvent?.id) {
            return editEvent({
                variables: {
                    id: activeEvent.id,
                    name,
                    categoryId,
                    venueId: selectedVenueId ? selectedVenueId : venueId,
                    startDate,
                    endDate,
                    description,
                    imageUrl,
                    link,
                    ticketPurchaseUrl,
                    affiliateUrl: affiliateUrl,
                    affiliateText,
                    customPinColor,
                    affiliateSvgUrl,
                    affiliateButtonColor,
                    affiliateSvgColor,
                    supportText,
                    supportSvgUrl,
                },
                refetchQueries: ["GetEvents"],
            }).then(onSubmit);
        }
        addEvent({
            variables: {
                name,
                categoryId,
                venueId: selectedVenueId ? selectedVenueId : venueId,
                startDate,
                endDate,
                description,
                imageUrl,
                link,
                ticketPurchaseUrl,
                affiliateUrl: affiliateUrl,
                affiliateText,
                customPinColor,
                affiliateSvgUrl,
                affiliateButtonColor,
                affiliateSvgColor,
                supportText,
                supportSvgUrl,
            },
            refetchQueries: ["GetEvents"],
        }).then(onSubmit);
    }

    return (
        <div className={tw`fixed z-10 inset-0 overflow-y-auto`}>
            <div
                className={tw`flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0`}
            >
                <form method="POST" onSubmit={onSubmitForm}>
                    <div
                        className={tw`fixed inset-0 transition-opacity`}
                        aria-hidden="true"
                    >
                        <div
                            className={tw`absolute inset-0 bg-gray-500 opacity-75`}
                        ></div>
                    </div>
                    <span
                        className={tw`hidden sm:inline-block sm:align-middle sm:h-screen`}
                        aria-hidden="true"
                    >
                        &#8203;
                    </span>
                    <div
                        className={tw`inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full`}
                        role="dialog"
                        aria-modal="true"
                        aria-labelledby="modal-headline"
                    >
                        <div
                            className={tw`bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4`}
                        >
                            <h3
                                className={tw`text-lg leading-6 font-medium text-gray-900 flex justify-between`}
                                id="modal-headline"
                            >
                                Add event
                                <button onClick={onClose}>
                                    <CloseIcon width="20" height="20" />
                                </button>
                            </h3>
                            <div className={tw`py-4`}>
                                <label
                                    htmlFor="name"
                                    className={tw`block text-sm font-medium text-gray-700`}
                                >
                                    Name
                                </label>
                                <Input
                                    type="text"
                                    name="name"
                                    value={name}
                                    onChange={(event: any) =>
                                        setName(event.target.value)
                                    }
                                />

                                <label
                                    htmlFor="category"
                                    className={tw`block text-sm font-medium text-gray-700`}
                                >
                                    Category
                                </label>
                                <CategoryDropdown
                                    value={categoryId}
                                    setValue={setCategoryId}
                                />

                                {!selectedVenueId && (
                                    <>
                                        <label
                                            htmlFor="venue"
                                            className={tw`block text-sm font-medium text-gray-700`}
                                        >
                                            Venue
                                        </label>
                                        <VenueDropdown
                                            value={venueId}
                                            setValue={setVenueId}
                                        />
                                    </>
                                )}

                                <label
                                    htmlFor="date"
                                    className={tw`block text-sm font-medium text-gray-700`}
                                >
                                    Date
                                </label>

                                <Calendar
                                    value={[startDate, endDate]}
                                    onChange={(dates) => {
                                        const [startDate, endDate] =
                                            dates as Date[];

                                        setStartDate(startDate);
                                        setEndDate(endDate);
                                    }}
                                    selectRange={true}
                                />

                                <label
                                    htmlFor="date"
                                    className={tw`block text-sm font-medium text-gray-700`}
                                >
                                    Start time
                                </label>

                                <TimePicker
                                    value={moment(startDate)}
                                    onChange={(date) =>
                                        setStartDate(date.toDate())
                                    }
                                />
                                <label
                                    htmlFor="date"
                                    className={tw`block text-sm font-medium text-gray-700`}
                                >
                                    End time
                                </label>

                                <TimePicker
                                    value={moment(endDate)}
                                    onChange={(date) =>
                                        setEndDate(date.toDate())
                                    }
                                />
                                <label
                                    htmlFor="description"
                                    className={tw`block text-sm font-medium text-gray-700`}
                                >
                                    Description (optional)
                                </label>
                                <Input
                                    type="text"
                                    name="description"
                                    value={description}
                                    onChange={(event: any) =>
                                        setDescription(event.target.value)
                                    }
                                />

                                <label
                                    htmlFor="image"
                                    className={tw`block text-sm font-medium text-gray-700`}
                                >
                                    Default image (optional)
                                </label>
                                <MediaLibrary
                                    onChange={setImageUrl}
                                    previewUrl={imageUrl}
                                />

                                <label
                                    htmlFor="link"
                                    className={tw`block text-sm font-medium text-gray-700`}
                                >
                                    Link (optional)
                                </label>
                                <Input
                                    type="text"
                                    name="link"
                                    value={link}
                                    onChange={(event: any) =>
                                        setLink(event.target.value)
                                    }
                                />
                                <label
                                    htmlFor="purchaseTicketLink"
                                    className={tw`block text-sm font-medium text-gray-700`}
                                >
                                    Purchase ticket link (optional)
                                </label>
                                <Input
                                    type="text"
                                    name="purchaseTicketLink"
                                    value={ticketPurchaseUrl}
                                    onChange={(event: any) =>
                                        setTicketPurchaseUrl(event.target.value)
                                    }
                                />
                                <button
                                    onClick={() =>
                                        setShowAdvancedOptions(
                                            (options) => !options
                                        )
                                    }
                                    className={`
                                    ${showAdvancedOptions ? "" : "collapsed"}
        accordion-button
        relative
        flex
        items-center
        w-full
        py-4
        px-5
        text-base text-gray-800 text-left
        bg-white
        border-0
        rounded-none
        transition
        focus:outline-none
      `}
                                    type="button"
                                    data-bs-toggle="collapse"
                                    data-bs-target="#collapseOne"
                                    aria-expanded="true"
                                    aria-controls="collapseOne"
                                >
                                    Advanced options
                                </button>
                                {showAdvancedOptions && (
                                    <div>
                                        <label
                                            htmlFor="affiliateLink"
                                            className={tw`block text-sm font-medium text-gray-700`}
                                        >
                                            Affiliate link (optional)
                                        </label>
                                        <Input
                                            type="text"
                                            name="affiliateLink"
                                            value={affiliateUrl}
                                            onChange={(event: any) =>
                                                setAffiliateUrl(
                                                    event.target.value
                                                )
                                            }
                                        />

                                        <label
                                            htmlFor="affiliateText"
                                            className={tw`block text-sm font-medium text-gray-700`}
                                        >
                                            Affiliate button text (optional)
                                        </label>

                                        <Input
                                            type="text"
                                            name="affiliateText"
                                            value={affiliateText}
                                            onChange={(event: any) =>
                                                setAffiliateText(
                                                    event.target.value
                                                )
                                            }
                                        />

                                        <label
                                            htmlFor="affiliateButtonColor"
                                            className={tw`block text-sm font-medium text-gray-700`}
                                        >
                                            Affiliate button color (optional)
                                        </label>
                                        <Input
                                            type="color"
                                            name="affiliateButtonColor"
                                            value={affiliateButtonColor}
                                            onChange={(event: any) =>
                                                setAffiliateButtonColor(
                                                    event.target.value
                                                )
                                            }
                                        />

                                        <label
                                            htmlFor="affiliateButtonIcon"
                                            className={tw`block text-sm font-medium text-gray-700`}
                                        >
                                            Affiliate button icon (optional)
                                        </label>
                                        <MediaLibrary
                                            filter="svg"
                                            previewUrl={affiliateSvgUrl}
                                            onChange={setAffiliateSvgUrl}
                                        />

                                        <label
                                            htmlFor="affiliateSvgColor"
                                            className={tw`block text-sm font-medium text-gray-700`}
                                        >
                                            Affiliate icon COLOR (optional)
                                        </label>
                                        <Input
                                            type="color"
                                            name="affiliateSvgColor"
                                            value={affiliateSvgColor}
                                            onChange={(event: any) =>
                                                setAffiliateSvgColor(
                                                    event.target.value
                                                )
                                            }
                                        />

                                        <label
                                            htmlFor="customPinColor"
                                            className={tw`block text-sm font-medium text-gray-700`}
                                        >
                                            Custom Pin Color (optional)
                                        </label>
                                        <Input
                                            type="color"
                                            name="customPinColor"
                                            value={customPinColor}
                                            onChange={(event: any) =>
                                                setCustomPinColor(
                                                    event.target.value
                                                )
                                            }
                                        />

                                        <label
                                            htmlFor="supportSvgUrl"
                                            className={tw`block text-sm font-medium text-gray-700`}
                                        >
                                            Support svg (optional)
                                        </label>
                                        <MediaLibrary
                                            filter="svg"
                                            previewUrl={supportSvgUrl}
                                            onChange={setSupportSvgUrl}
                                        />

                                        <label
                                            htmlFor="supportSvgUrl"
                                            className={tw`block text-sm font-medium text-gray-700`}
                                        >
                                            Support text (optional)
                                        </label>
                                        <Input
                                            type="text"
                                            name="supportText"
                                            value={supportText}
                                            onChange={(event: any) =>
                                                setSupportText(
                                                    event.target.value
                                                )
                                            }
                                        />
                                    </div>
                                )}
                            </div>
                        </div>
                        <div
                            className={tw`bg-gray-50 px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse`}
                        >
                            <Button
                                disabled={
                                    !Boolean(name) ||
                                    !Boolean(categoryId) ||
                                    (!Boolean(venueId) &&
                                        !Boolean(selectedVenueId)) ||
                                    !startDate
                                }
                                type="submit"
                                variant="primary"
                            >
                                {activeEvent?.id ? "Save" : "Add"}
                            </Button>
                            <Button
                                type="button"
                                onClick={onClose}
                                variant="secondary"
                            >
                                Cancel
                            </Button>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    );
}
