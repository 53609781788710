import { useQuery } from "@apollo/client";
import Dropdown from "components/dropdown/Dropdown";
import { GET_VENUES } from "graphql/queries";
import React from "react";

interface Props {
    value?: string;
    setValue(value: string): void;
}

export interface Venue {
    id: string;
    name: string;
}

export type Venues = Venue[];

export default function VenueDropdown({ value, setValue }: Props) {
    const { data } = useQuery<{ venues: Venues }>(GET_VENUES);

    return data?.venues ? (
        <Dropdown
            items={data?.venues}
            onSelect={(item) => setValue(item.id)}
            selectedItemId={value}
        />
    ) : null;
}
