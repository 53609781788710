import { gql } from "@apollo/client";

export const GET_VENUES = gql`
    query GetVenues($limit: Float, $offset: Float, $search: String) {
        venues(
            venuesInput: { limit: $limit, offset: $offset, search: $search }
        ) {
            id
            name
            addressText
            link
            description
            full_count
        }
    }
`;

export const GET_MEDIA = gql`
    query GetMedia(
        $limit: Float
        $offset: Float
        $search: String
        $filter: String
    ) {
        media(
            media: {
                limit: $limit
                offset: $offset
                search: $search
                filter: $filter
            }
        ) {
            media {
                id
                filename
                location
            }
            full_count
        }
    }
`;

export const ME = gql`
    query Me {
        me {
            id
            email
        }
    }
`;

export const GET_USERS = gql`
    query GetUsers {
        users {
            id
            email
            role
        }
    }
`;

export const GET_VENUE = gql`
    query GetVenue($venueId: String!) {
        venue(venueId: $venueId) {
            id
            name
            addressText
            link
        }
    }
`;

export const GET_EVENTS = gql`
    query GetEvents(
        $venueId: String
        $offset: Float
        $limit: Float
        $search: String
        $importedOnly: Boolean
        $showOutdated: Boolean
    ) {
        events(
            events: {
                venueId: $venueId
                limit: $limit
                offset: $offset
                search: $search
                importedOnly: $importedOnly
                showOutdated: $showOutdated
            }
        ) {
            id
            name
            description
            venue {
                id
                name
            }
            category {
                id
                name
            }
            startDate
            endDate
            link
            imageUrl
            ticketPurchaseUrl
            full_count
            importedFile
            affiliateUrl
            affiliateText
            affiliateSvgUrl
            affiliateSvgColor
            affiliateButtonColor
            customPinColor
            supportText
            supportSvgUrl
        }
    }
`;

export const GET_CATEGORIES = gql`
    query GetCategories {
        categories {
            id
            name
            svgUrl
            imageUrl
        }
    }
`;

export const GET_PINS = gql`
    query GetPins($limit: Float, $offset: Float, $search: String) {
        pins(pinsInput: { limit: $limit, offset: $offset, search: $search }) {
            id
            name
            addressText
            link
            description
            color
            svg
            svgUrl
            full_count
            buttonText
        }
    }
`;

export const GET_ANNOUNCEMENTS = gql`
    query GetAnnouncements($limit: Float, $offset: Float, $search: String) {
        announcements(
            announcementsInput: {
                limit: $limit
                offset: $offset
                search: $search
            }
        ) {
            id
            text
        }
    }
`;
