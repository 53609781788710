import Button from "components/button/Button";
import CsvImport from "components/csv-import/CsvImport";
import Search from "components/search/Search";
import React from "react";
import { tw } from "twind";
import Footer from "./Footer";

interface Column {
    value: string;
    render?(value: string): React.ReactElement | string;
    name: string;
    maxLength?: number;
}

interface Filter {
    text: string;
    onChange(value: boolean): void;
    value: boolean;
}
interface Props {
    title?: string;
    columns: ReadonlyArray<Column>;
    data: ReadonlyArray<{ [key: string]: any }>;
    alertTitle?: string;
    alertDescription?: string;
    recordCount?: number;
    offset?: number;
    limit?: number;
    onPageChange?(page: number): void;
    onAdd?(): void;
    onClick?(row: any): void;
    onDelete?(id: string): void;
    onEdit?(input: any): void;
    searchText?: string;
    onChangeText?(text: string | undefined): void;
    venueId?: string;
    filters?: Filter[];
}

export default function Table({
    columns,
    data,
    onAdd,
    onClick,
    onEdit,
    title,
    onDelete,
    alertTitle,
    alertDescription,
    recordCount,
    offset,
    limit,
    onPageChange,
    onChangeText,
    searchText,
    venueId,
    filters,
}: Props) {
    const onRowClick = (row: any) => () => {
        if (onClick) {
            onClick(row);
        }
    };

    return (
        <div className={tw`max-w-7xl mx-auto py-8 sm:px-6 lg:px-8`}>
            <div className={tw`flex flex-col`}>
                <div className={tw`-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8`}>
                    <div
                        className={tw`py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8`}
                    >
                        {title && (
                            <h1 className={tw`text-2xl py-4`}>
                                {title.charAt(0).toUpperCase() + title.slice(1)}
                            </h1>
                        )}
                        {!!onChangeText && (
                            <Search
                                text={searchText ?? ""}
                                onChangeText={onChangeText}
                            />
                        )}
                        <br />
                        {filters?.map((filter) => (
                            <label style={{ paddingRight: 10 }}>
                                <input
                                    type="checkbox"
                                    style={{ paddingRight: 5 }}
                                    onChange={(event) =>
                                        filter.onChange?.(
                                            event.currentTarget.checked
                                        )
                                    }
                                    checked={filter.value}
                                />
                                {filter.text}
                            </label>
                        ))}
                        <div
                            className={tw`shadow overflow-hidden border-b border-gray-200 sm:rounded-lg`}
                        >
                            <table
                                className={tw`min-w-full divide-y divide-gray-200`}
                            >
                                <thead className={tw`bg-gray-50`}>
                                    <tr>
                                        {columns?.map((column) => (
                                            <th
                                                key={column.name}
                                                scope="col"
                                                className={tw`px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider`}
                                            >
                                                {column.name}
                                            </th>
                                        ))}
                                        <th
                                            scope="col"
                                            className={tw`relative px-6 py-3 text-right`}
                                        >
                                            <Button
                                                type="button"
                                                variant={"primary"}
                                                onClick={onAdd!}
                                            >
                                                <span>Add</span>
                                            </Button>
                                        </th>
                                    </tr>
                                </thead>
                                <tbody
                                    className={tw`bg-white divide-y divide-gray-200`}
                                >
                                    {data?.map((row) => {
                                        return (
                                            <tr
                                                className={
                                                    onClick
                                                        ? tw`cursor-pointer`
                                                        : ``
                                                }
                                                onClick={onRowClick(row)}
                                                key={row.id}
                                            >
                                                {columns?.map((column) => (
                                                    <td
                                                        className={tw`px-6 py-4 whitespace-nowrap`}
                                                        key={column.value}
                                                    >
                                                        <div
                                                            className={tw`text-sm text-gray-900`}
                                                        >
                                                            {column.render
                                                                ? column.render?.(
                                                                      getProperty(
                                                                          row,
                                                                          column.value
                                                                      )
                                                                  )
                                                                : getProperty(
                                                                      row,
                                                                      column.value
                                                                  )?.substr(
                                                                      0,
                                                                      column.maxLength ||
                                                                          50
                                                                  )}
                                                        </div>
                                                    </td>
                                                ))}
                                                <td
                                                    className={tw`px-6 py-4 whitespace-nowrap text-right text-sm font-medium`}
                                                >
                                                    {onEdit && (
                                                        <Button
                                                            type="button"
                                                            variant="secondary"
                                                            onClick={(
                                                                event
                                                            ) => {
                                                                event.stopPropagation();
                                                                onEdit(row);
                                                            }}
                                                        >
                                                            <span>Edit</span>
                                                        </Button>
                                                    )}
                                                    {onDelete &&
                                                        row.id &&
                                                        alertTitle &&
                                                        alertDescription && (
                                                            <Button
                                                                type="button"
                                                                variant={
                                                                    "dangerous"
                                                                }
                                                                onClick={(
                                                                    event
                                                                ) => {
                                                                    event.stopPropagation();
                                                                    onDelete(
                                                                        row.id
                                                                    );
                                                                }}
                                                            >
                                                                <span>
                                                                    Delete
                                                                </span>
                                                            </Button>
                                                        )}
                                                </td>
                                            </tr>
                                        );
                                    })}
                                </tbody>
                            </table>
                            {offset !== undefined &&
                                limit !== undefined &&
                                recordCount !== undefined && (
                                    <Footer
                                        onPageChange={onPageChange}
                                        offset={offset}
                                        limit={limit}
                                        recordCount={recordCount}
                                    />
                                )}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

function getProperty(object: any, propertyName: string) {
    var parts = propertyName.split("."),
        length = parts.length,
        i,
        property = object;

    for (i = 0; i < length; i++) {
        property = property[parts[i]];
    }

    return property;
}
