import { tw } from "twind";
import { DebounceInput } from "react-debounce-input";

export interface Props {
    text: string;
    onChangeText?(text: string | undefined): void;
}

export default function Search({ text, onChangeText }: Props) {
    function onChange(event: any) {
        onChangeText?.(event?.target?.value);
    }

    return (
        <DebounceInput
            type="text"
            value={text}
            debounceTimeout={300}
            placeholder="Search name"
            className={tw`border border-gray-300 bg-white text-gray-900 appearance-none block w-full rounded-md mb-4 py-2 px-3 focus:border-blue-500 focus:outline-none`}
            onChange={onChange}
        />
    );
}
