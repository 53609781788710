import { useMutation } from "@apollo/client";
import { CSV_UPLOAD } from "graphql/mutations";
import React from "react";

interface Props {
    venueId?: string;
}
export default function UploadCsv({ venueId }: Props) {
    const [csvUpload, { loading, data }] = useMutation<{
        importCsv: { success: boolean; errors: string[] };
    }>(CSV_UPLOAD);

    function onInputChange({
        currentTarget: { validity, files },
    }: React.ChangeEvent<HTMLInputElement>) {
        const file = files?.[0];
        if (file && validity.valid) {
            csvUpload({
                variables: { file, venueId },
                refetchQueries: ["GetEvents"],
            });
        }
    }

    console.log(csvUpload);
    return (
        <div>
            {loading && "Uploading..."}
            {!loading && (
                <input
                    name="image"
                    onChange={onInputChange}
                    type="file"
                    accept=".csv"
                />
            )}
            {data?.importCsv.errors && (
                <p style={{ color: "red" }}>
                    {data?.importCsv.errors.join("/n")}
                </p>
            )}
            {data?.importCsv.success && (
                <p style={{ color: "green" }}>Great success!</p>
            )}
        </div>
    );
}
