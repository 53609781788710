import { tw } from "twind";

export interface Media {
    id: string;
    filename: string;
    location: string;
}

export default function Media({
    id,
    filename,
    location,
    onClick,
}: Media & { onClick?(media: Media): void }) {
    function onMediaClick() {
        onClick?.({ id, filename, location });
    }

    return (
        <div
            onClick={onMediaClick}
            className={tw`w-full rounded aspect-square h-52`}
        >
            <span className={tw`truncate text-elipsis w-full block`}>
                {filename}
            </span>
            <img
                src={location}
                className={tw`w-full object-cover object-center`}
            />
        </div>
    );
}
